import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'
import ContentBlock from '../components/ContentBlock/ContentBlock'
import PageTitle from '../components/PageTitle/PageTitle'
import { Container } from 'react-bootstrap'
import { graphql } from 'gatsby'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import PostLinks from '../components/PostLinks'
import {Row, Col } from 'react-bootstrap'
import EventsSidebar from '../components/EventsBlock/EventsSidebar'


const EventPageTemplate = ({ data, pageContext }) => {
    console.log('data:', data)
    const post = data.contentfulEventPost;
    const previous = pageContext.prev
    const next = pageContext.next
    
    //const { previous, next } = this.props.pageContext
    

    const options = {
        renderNode: {
            
            [BLOCKS.EMBEDDED_ASSET]: ({data: { target: { fields }}}) => 
                <div dangerouslySetInnerHTML={{
                    __html: `<div style="width: auto; ">
                    <img src="${fields.file["en-US"].url}" alt="${fields.title["en-US"]}" class="img-fluid" style="margin: 0px" />
                    </div>`,
                  }} />,

                [BLOCKS.EMBEDDED_ENTRY]: (node) => {
                    console.log(node.data.target.fields.images)
                    const fields = node.data.target.fields.images["en-US"];
                        return (
                            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                            {fields.map((galleryImage) =>
                                <div style={{width: '50%', padding: '1rem'}} dangerouslySetInnerHTML={{__html: `<div><img src="${galleryImage.fields.file["en-US"].url}" alt="${galleryImage.fields.title}" style="width: 100%" />
                                <small>${galleryImage.fields.description["en-US"]}</small>
                                </div>` }} />
                            )}
                            </div>
                            
                        
                        )
                    },
                
                // Embed iframe content
                [INLINES.EMBEDDED_ENTRY]: (node) => {
                    const embed = node.data.target.fields?.content || node.data.target.fields?.markdownContent
                   // console.log('embed data: ' + embed['en-US'])
                    return (
                        <div dangerouslySetInnerHTML={{__html: `${embed['en-US']}`}} />
                    )
                },
        }
    }

    return (
        <Layout>
        <SEO title={post.title}/>
        <Container fluid className="container-fluid-full">
          <PageTitle pageTitle={post.title} >
            {/* {page.pageTitle} */}
          </PageTitle>
          <ContentBlock>
              <Row>
                  <Col sm={9}>
                  <div>
                <h2 style={{textTransform: 'none'}}>{post?.eventDate}</h2>
            </div> 
            <div>
                {/* <img src={post.featuredImage ? post.featuredImage.fluid.src : null} /> */}
                
                {/* <img src={post.eventFeaturedImage.fluid.src} style={{ width: '100%', marginBottom: '20px'}} /> */}
                {/* <div dangerouslySetInnerHTML={{__html: post.newsContent.childContentfulRichText.html}}/> */}

                <div>
                    {documentToReactComponents(post.childContentfulEventPostPostContentRichTextNode.json, options)}
                </div>
                <PostLinks previous={previous} next={next}  />
            </div>
                  </Col>
                  <Col>
                    <div className="card-box">
                        <h2 className="text-center">USS IDAHO Events</h2>
                        <hr />
                        <div>
                    <EventsSidebar />
                    </div>
                    </div>
                    
                  </Col>
              </Row>
        

            </ContentBlock>
            </Container>
        </Layout>
    )
}

export const getBlogPost = graphql`
    query ContentfulBlogPostBySlugAndContentfulBlogPostBySlug($slug: String!) {
        contentfulEventPost( slug: { eq: $slug }) {
            eventDate(formatString: "MMMM Do, YYYY")
            childContentfulEventPostPostContentRichTextNode {
                json
            }
            childContentfulEventPostPostExcerptTextNode {
                postExcerpt
            }
            featuredImage {
                file {
                    url
                }
            fluid(maxWidth: 800) {
                sizes
                src
                srcSet
            }
        }
                title
            }
    }
`

export default EventPageTemplate
import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

const EventsSidebar = () => {
    const data = useStaticQuery(graphql`
    {
    allContentfulEventPost(limit: 3,
        sort: { fields: [createdAt], order: DESC}) {
        totalCount
        edges {
          node {
            slug
            title
            eventDate(formatString: "MMMM Do, YYYY")
            updatedAt(formatString: "MMMM Do, YYYY")
            childContentfulEventPostPostExcerptTextNode {
              postExcerpt 
            }
            image: featuredImage {
              fluid(maxWidth: 600) {
                src
                srcSet
              }
            }
          }
        }
      }
    }
  `)
  const posts = data.allContentfulEventPost.edges
  return (
      <>
         { posts.map(({ node }) => {
       return (
           <>
        <p>
            <Link to={`/events/${node.slug}`}
                 style={{fontWeight: 700}}
            >
               {node.title}
            </Link>
            <br />
            {node.eventDate}
        </p>
        <hr />
        </>
        
       )
     }) }
      </>
  )
}

export default EventsSidebar